import './App.css';
import FileUploadComponent from './components/FileUpload/FileUpload';
import FileList from './components/FileList/FileList';
import Header from './components/Header/Header';
import { Helmet } from 'react-helmet';

function App() {
  return (
    <div className="App">
    <Helmet>
        <title>Build a bot!</title>
        <meta charSet="utf-8" />
        <meta name="description" content="Platform to create and manage chatbots."/>
    </Helmet>
    <Header title="Create and Manage your Chatbots!" />
      <header className="App-header">
        <p>
          CLIENT ADMIN PORTAL
        </p>
        <FileUploadComponent />
        <FileList />
      </header>
    </div>
  );
}

export default App;
