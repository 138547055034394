import React, { useState, useEffect } from 'react';
import { fetchGetData } from '../utils/api';

function FileList() {
  const [files, setFiles] = useState([]);
  const path = 'files/list-files?clientId=1'

  useEffect(() => {
    fetchGetData(path).then((data) => {
      setFiles(data["files"]);
    });
  }, []);

  return (
    <div>
      <h2>List of Files</h2>
      <ul>
        {files.map((file, index) => (
          <li key={index}>{file}</li>
        ))}
      </ul>
    </div>
  );
}

export default FileList;
