import React, { useState } from 'react';
import { uploadFile } from '../utils/api';

function FileUploadComponent() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      console.warn('No file selected');
      return;
    }

    try {
      setUploading(true);
      console.log('Uploading file:', selectedFile);
      const uploadResponse = await uploadFile(selectedFile);
      console.log('Upload response:', uploadResponse);
      // Handle successful upload
    } catch (error) {
      console.error('Upload error:', error);
      // Handle upload error
    } finally {
      setUploading(false);
    }
  };

  return (
    <div>
      <input type="file" onChange={handleFileChange} />
      <button onClick={handleUpload} disabled={!selectedFile || uploading}>
        {uploading ? 'Uploading...' : 'Upload'}
      </button>
    </div>
  );
};

export default FileUploadComponent;
