const API_URL = process.env.REACT_APP_API_URL;


export const fetchGetData = async (path) => {
    const url = `${API_URL}/${path}`
    const response = await fetch(url);
    const data = await response.json();
    return data;
  };
  
  
export const fetchPostData = async (path, payload) => {
    const url = `${API_URL}/${path}`
    const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
    });
    const data = await response.json();
    return data;
};
  

export const uploadFile = async (file) => {
    const url = `${API_URL}/files/upload`;
    const formData = new FormData();
    formData.append('file', file);
    
    const response = await fetch(url, {
        method: 'POST',
        body: formData,
    });

    if (!response.ok) {
        throw new Error('Failed to upload file');
    }

    const data = await response.json();
    return data;
};